<template>
	<div style="padding: 10px;">
		<div>
			海鼎ID：<span>
				<el-input v-model="hdId"></el-input>
			</span>
		</div>
		<div>
			<el-table :data="tableData" style="width: 100%">
        <el-table-column prop="hd_id" label="海鼎id" width="100"></el-table-column>
        <el-table-column label="门店名称" width="150">
          <template slot-scope="{row}">
            <span v-for="value in userData" :key="value.hd_code">
              {{ value.hd_code === row.hd_id ? value.username : '' }}
            </span>
          </template>
        </el-table-column>
				<el-table-column prop="id" label="删除日期" width="100">
					<template slot-scope="{row}">
						{{row.created_at|formatDateStr("yyyy-MM-dd")}}
					</template>
				</el-table-column>
        <el-table-column>
          <template slot-scope="{row}">
            <el-button type="primary" plain @click="handleOpen(row.hd_id,row.created_at)">查看</el-button>
          </template>
        </el-table-column>
			</el-table>
      <!-- 分页 -->
      <div ref="footer" class="footer">
        <el-pagination :key="reloadPaginate" :current-page="pagination.page" :page-size="pagination.pagesize"
                       :page-sizes="[10, 20, 50, 100, 200]" layout="total,sizes, prev, pager, next, jumper"
                       :total="pagination.total" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       background />
      </div>
		</div>
    <el-drawer
      :title="'购物车详情:（海鼎id：' + rowInfo.hd_id + '（' +  rowInfo.username + '）| 删除时间：' +  rowInfo.deleteDate +  ' 总价：' + total +' ）'"
      :visible.sync="cartCleanGoodsWrap"
      direction="rtl"
      size="70%">
      <div class="drawer-content">
        <div style="float: right;padding: 8px 10px;">
          <el-button type="primary" plain
                     v-clipboard:copy="copyData"
                     v-clipboard:success="onCopy"
                     v-clipboard:error="onError">复制</el-button>
        </div>
        <el-table :data="goodsData" height="750">
          <el-table-column prop="item_no" label="编号" min-width="120"></el-table-column>
          <el-table-column prop="item_name" label="商品名称" min-width="200"></el-table-column>
          <el-table-column label="图片" width="150" align="center">
            <template slot-scope="scope">
              <img height="80"
                   :src="`http://hwimg.xmvogue.com/thumb/${scope.row.item_no}.jpg?x-oss-process=style/440`"
                   class="mr-3" alt="..." />
            </template>
          </el-table-column>
          <el-table-column prop="base_price" label="价格" min-width="80"></el-table-column>
          <el-table-column prop="carts_num" label="数量" min-width="80"></el-table-column>
          <el-table-column prop="row" label="总价" min-width="80">
            <template slot-scope="scope">
              {{ ((scope.row.base_price * 1000) * scope.row.carts_num)/1000 }}
            </template>
          </el-table-column>
        </el-table>
        <div ref="drawer-footer" class="drawer-footer">
          <el-pagination :key="drawReloadPaginate" :current-page="drawerPagination.page" :page-size="drawerPagination.pagesize"
                         :page-sizes="[10, 20, 50, 100, 200]" layout="total,sizes, prev, pager, next, jumper"
                         :total="drawerPagination.total"
                         @size-change="handleDrawerSizeChange"
                         @current-change="handleDrawerCurrentChange"
                         background />
        </div>
      </div>
    </el-drawer>
	</div>
</template>

<script>
import common from "@/common/mixins/common.js";
import { formatTime } from "@/common/utils/date.js";

export default {
		mixins: [common],
		data() {
			return {
				hdId: "16244",
				tableData: [],
        userData:[],
        reloadPaginate: 0,
        pagination: {
          total: 0,
          page: 1,
          pagesize: 10,
        },
        cartCleanGoodsWrap:false,
        goodsData:[],
        drawReloadPaginate:0,
        drawerPagination:{
          total:0,
          page:1,
          pagesize:10
        },
        rowInfo:{
          hd_id:'',
          deleteDate:'',
          username:''
        },
        copyData:"",
        total:0
			}
		},
		watch:{
			"hdId"(){
				this.getCleanData()
			}
		},
		created() {
      this.hdId = ""
		},
		methods: {
			getCleanData(){
        let params = {
          page:this.pagination.page,
          pagesize:this.pagination.pagesize
        }
        if (this.hdId) params.hd_id = this.hdId
				this.$axios.get(`/a1/cartClean`,{params:params}).then((res)=>{
          this.tableData = res.data.data.data.CartsClean;
          this.userData = res.data.data.data.Users;
          this.pagination.total = res.data.data.total
          this.reloadPaginate ++
        })
			},
      //复制
      onCopy: function() {
        this.$message.success("已复制");
      },
      onError: function() {
        this.$message.success("复制失败");
      },
      // 分页跳转
      handleSizeChange(val) {
        this.page = this.pagination.pagesize = val;
        this.getCleanData();
      },
      handleCurrentChange(val) {
        this.page = this.pagination.page = val;
        this.getCleanData();
      },
      async handleOpen(hd_id,date){
        this.rowInfo.hd_id = hd_id
        this.rowInfo.deleteDate = formatTime('yyyy-MM-dd',new Date(date * 1000))

        this.userData.forEach(item => {

          if(item.hd_code === hd_id){
            this.rowInfo.username = item.username
          }

        })

        let params = {
          page:1,
          pagesize:this.drawerPagination.pagesize,
          deleteTime:formatTime('yyyyMMdd',new Date(date * 1000)),
          hd_id:hd_id
        }
        const data = await this.getCartCleanGoods(params);

        this.goodsData = data.data.data
        this.drawerPagination.total = data.total

        this.total = data.data.total.toFixed(2)

        this.cartCleanGoodsWrap = true

        this.handleCopy()

      },
      handleClose(){},
      async handleDrawerSizeChange(val){

        this.drawerPagination.pagesize = val
        this.drawerPagination.page = 1

        let params = {
          page:this.drawerPagination.page,
          pagesize:val,
          deleteTime:formatTime('yyyyMMdd',this.rowInfo.deleteDate),
          hd_id:this.rowInfo.hd_id
        }
        const data = await this.getCartCleanGoods(params)
        this.goodsData = data.data.data
        this.drawerPagination.total = data.total

      },
      async handleDrawerCurrentChange(val){
        this.drawerPagination.page = val
        let params = {
          page:val,
          pagesize:this.drawerPagination.pagesize,
          deleteTime:formatTime('yyyyMMdd',this.rowInfo.deleteDate),
          hd_id:this.rowInfo.hd_id
        }
        const data = await this.getCartCleanGoods(params)
        this.goodsData = data.data.data
        this.drawerPagination.total = data.total
      },
      async getCartCleanGoods(params){
        const res = await this.$axios.get('/a1/cartClean/goods',{params:params})
        this.drawReloadPaginate ++
        return res.data.data
      },
      handleCopy(){

        //获取数据
        let params = {
          page:1,
          pagesize:1000,
          deleteTime:formatTime('yyyyMMdd',this.rowInfo.deleteDate),
          hd_id:this.rowInfo.hd_id
        }
        this.$axios.get('/a1/cartClean/list',{params:params}).then(res => {

          const data = res.data.data
          let dataS = ""

          data.forEach((row) => {
            dataS += row.d_value + "\r\n"
          })

          this.copyData = dataS
        })

      }
		},
	}
</script>

<style lang="scss" scoped>

.footer{
  padding-top: 10px;
}

.drawer-content{
  padding:0 30px;
}
.drawer-footer{
  padding-top: 10px;
}
</style>
