export default {
  filters: {
    //数字转字符串
    numToString(value) {
      return value.toString();
    },
    formatDate(value) {
      return value * 1000;
    },
    //格式化时间戳，转成日期格式
    formatDateStr: function (value, format = "yyyy-MM-dd hh:mm:ss") {
      if (value == null || value === 0) {
        return "-";
      } else {
        value = value * 1000;
        let date = new Date(value);
        const o = {
          "M+": date.getMonth() + 1, //月份
          "d+": date.getDate(), //日
          "h+": date.getHours(), //小时
          "m+": date.getMinutes(), //分
          "s+": date.getSeconds(), //秒
          "q+": Math.floor((date.getMonth() + 3) / 3), //季度
          S: date.getMilliseconds(), //毫秒
        };
        if (/(y+)/.test(format)) {
          format = format.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length),
          );
        }
        for (var k in o) {
          if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(
              RegExp.$1,
              RegExp.$1.length === 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length),
            );
          }
        }
        return format;
      }
    },
    //字符串截取
    cutstr(str, lennum) {
      if (str) {
        let len = lennum ? lennum : 30;
        let arrBox = [...str];
        let newStr = "";
        if (arrBox.length > len) {
          arrBox.map((res, index) => {
            if (index < len) {
              newStr += res;
            }
          });
          newStr += "...";
          return newStr;
        } else {
          return str;
        }
      }
    },
    // 字符截取后两位
    toFixed2(flo) {
      return flo.toFixed(2);
    }
  },
};
