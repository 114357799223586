const TIME_REG=/^(?:(\d{4})(?:[-\/](\d{1,2})(?:[-\/](\d{1,2})(?:[T ](\d{1,2})(?::(\d\d)(?::(\d\d)(?:[.,](\d+))?)?)?(Z|[\+\-]\d\d:?\d\d)?)?)?)?)?$/; // jshint ignore:line

function pad(str, len) {
  str+="";
  return "0000".substr(0, len - str.length) + str;
}

function parseDate(value) {
  if (value instanceof Date) {
    return value;
  } else if (typeof value === "string") {
    // Different browsers parse date in different way, so we parse it manually.
    // Some other issues:
    // new Date('1970-01-01') is UTC,
    // new Date('1970/01/01') and new Date('1970-1-01') is local.
    // See issue #3623
    const match=TIME_REG.exec(value);

    if (!match) {
      // return Invalid Date.
      return new Date(NaN);
    } // Use local time when no timezone offset specifed.


    if (!match[8]) {
      // match[n] can only be string or undefined.
      // But take care of '12' + 1 => '121'.
      return new Date(+match[1], +(match[2] || 1) - 1, +match[3] || 1, +match[4] || 0, +(match[5] || 0), +match[6] || 0, +match[7] || 0);
    } // Timezoneoffset of Javascript Date has considered DST (Daylight Saving Time,
      // https://tc39.github.io/ecma262/#sec-daylight-saving-time-adjustment).
      // For example, system timezone is set as "Time Zone: America/Toronto",
      // then these code will get different result:
      // `new Date(1478411999999).getTimezoneOffset();  // get 240`
      // `new Date(1478412000000).getTimezoneOffset();  // get 300`
    // So we should not use `new Date`, but use `Date.UTC`.
    else {
      let hour=+match[4] || 0;

      if (match[8].toUpperCase() !== "Z") {
        hour-=match[8].slice(0, 3);
      }

      return new Date(Date.UTC(+match[1], +(match[2] || 1) - 1, +match[3] || 1, hour, +(match[5] || 0), +match[6] || 0, +match[7] || 0));
    }
  } else if (value == null) {
    return new Date(NaN);
  }

  return new Date(Math.round(value));
}


/**
 * ISO Date format
 * @param {string} tpl
 * @param {Date} value
 * @param {boolean} [isUTC=false] Default in local time.
 *           see `module:echarts/scale/Time`
 *           and `module:echarts/util/number#parseDate`.
 * @inner
 */


function formatTime(tpl, value, isUTC=false) {
  if (tpl === "week" || tpl === "month" || tpl === "quarter" || tpl === "half-year" || tpl === "year") {
    tpl="MM-dd\nyyyy";
  }

  const date=parseDate(value);
  const utc=isUTC ? "UTC" : "";
  const y=date["get" + utc + "FullYear"]();
  const M=date["get" + utc + "Month"]() + 1;
  const d=date["get" + utc + "Date"]();
  const h=date["get" + utc + "Hours"]();
  const m=date["get" + utc + "Minutes"]();
  const s=date["get" + utc + "Seconds"]();
  const S=date["get" + utc + "Milliseconds"]();
  tpl=tpl.replace("MM", pad(M, 2))
    .replace("M", M)
    .replace("yyyy", y)
    .replace("yy", y % 100)
    .replace("dd", pad(d, 2))
    .replace("d", d)
    .replace("hh", pad(h, 2))
    .replace("h", h)
    .replace("mm", pad(m, 2))
    .replace("m", m)
    .replace("ss", pad(s, 2))
    .replace("s", s)
    .replace("SSS", pad(S, 3));
  return tpl;
}

export {
  formatTime,
};
